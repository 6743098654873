const $ = window.jQuery;
const Toastr = window.Toastr;
const CKEDITOR = window.CKEDITOR;
const Util = window.Util;
const NextAction = window.NextAction;

function initPlugins(contentSelector) {
  const ajax_content = $(contentSelector);
  ajax_content.unibutton().initAll();
  ajax_content.plugin();
  ajax_content.card();
}

class Card {
	constructor(element) {

		this.element = element;

		this.loaded = this.element.data('loaded') || false;

		if (!this.loaded) {
			this.loaded = this.init();
			if (this.loaded) {
				this.element.data('loaded', true);
			}
		}

		return this.loaded;
	}

	ckeditor() {
		let timeoutHandle;
		if(CKEDITOR.instances) {
			for(let [i] of CKEDITOR.instances) {
				CKEDITOR.instances[i].on('change', function(ev) {
					Console.trace();
					window.clearTimeout(timeoutHandle);
					const el = $(ev.editor.element.$);
					el.cardinput().handleChange();
					timeoutHandle = window.setTimeout(function() {
						el.cardinput().handleProcess();

						let params = $.extend({value: el.val()}, el.data());
						const props = Object.keys(params);
						for (let i = 0; i < props.length; i++) {
							if(typeof params[props[i]] === 'object') {
								delete params[props[i]];
							}
						}

						$.ajax({
							url: Util.blackbox('/app/dynproc/'),
							type: 'post',
							data: $.param(params),
							success: function(data) {
								if(Number(data.status) === 1) {
									el.cardinput().handleSave();
								} else {
									el.cardinput().handleError(null, null, data.message);
								}

								if(data.next_action) {
									new NextAction(data, el);
								}
							},
							error: function(XMLHttpRequest, textStatus, errorThrown) {
								el.cardinput().handleError(XMLHttpRequest, textStatus, errorThrown);
							}
						});
					}, 1000);
				});
			}
		}
	}


	select2() {
		$('.select2.cardinput', this.element).each(function () {
			$(this).on('change', function(event) {
				event.stopPropagation(); event.stopImmediatePropagation();
				const el = $(this);
				el.cardinput().handleProcess();

				$.ajax({
					url: Util.blackbox('/app/dynproc/'),
					type: 'post',
					data: Util.serialize({value: el.val()}, el.data('post')),
					success: function (data) {
						if (Number(data.status) === 1) {
							el.cardinput().handleSave();
						} else {
							el.cardinput().handleError(null, null, data.message);
						}

						if(data.next_action) {
							new NextAction(data.next_action, el);
						}
					},
					error: function (XMLHttpRequest, textStatus, errorThrown) {
						el.cardinput().handleError(XMLHttpRequest, textStatus, errorThrown);
					}
				});
			});
		});
		return 1;
	}

	input() {
		const validate_date = (value) =>
		{
			if(!value) {
				return true;
			}
			let arrD = value.split(".");
			arrD[1] -= 1;
			const d = new Date(arrD[2], arrD[1], arrD[0]);
			if ((d.getFullYear() > 1900) && (d.getFullYear() == arrD[2]) && (d.getMonth() == arrD[1]) && (d.getDate() == arrD[0])) {
				return true;
			} else {
				return false;
			}
		}

		const saveOnChange = (elem, ev) => {
			ev.stopPropagation(); ev.stopImmediatePropagation();
			const el = $(elem);
			if (typeof el.data('post').procname !== 'undefined'){
				el.cardinput().handleProcess();
				$.ajax({
					url: Util.blackbox('/app/dynproc/'),
					type: 'post',
					data: Util.serialize({value: el.attr('type') === 'checkbox' ? el.prop('checked') ? 1 : 0 : el.val()}, el.data('post')),
					success: function (data) {
						if (Number(data.status) === 1) {
							el.cardinput().handleSave();
						} else {
							el.cardinput().handleError(null, null, data.message);
						}

						if(data.next_action) {
							new NextAction(data.next_action, el);
						}
					},
					error: function (XMLHttpRequest, textStatus, errorThrown) {
						el.cardinput().handleError(XMLHttpRequest, textStatus, errorThrown);
					}
				});
			}
		};

		const toggleGroupCheckboxes = (that) => {
			const table = $(that).closest('table');
			const checkboxes = $(table).find('[data-post]');
			const ready = [];
			const btn_post = $(that).data('post').group || undefined;

			if (btn_post === undefined) { console.log('Group in data-post not found'); }

			checkboxes.each(function () {
				if (typeof btn_post !== 'undefined'){
					try {
						const check_post = $(this).data('post');
						if (check_post.group === btn_post && this.checked !== that.checked) {
							ready.push(this);
						}
					}catch (e) {
						SWAL.error(e);
					}
				}
			});
			$(ready).click();
		};
		

		for (const input of $('input.cardinput,textarea.cardinput', this.element).not('.card_multiple')) {
			let oldval = $(input).val();
			if (input.type === 'checkbox') {
				$(input).on('click', function (event) {
					saveOnChange(this, event);
					if (input.classList.contains('group_select')) {
						toggleGroupCheckboxes(input); }
				} );
			} else if($(input).hasClass("date-picker")) {
				$(input).on('change', function (event)  {
					console.log('value');
					console.log(this.value);
					if(validate_date(this.value)) {
						console.log("changeData");
						saveOnChange(this, event);
					}
				})
				
			} else {
				$(input).on('change', function (event) { console.log("change"); saveOnChange(this, event); } );
			}					

			if ($(input).data('mask') && $(input).data('mask') !== '' && !$(input).hasClass("date-picker")) {
				          $(input).on('focusout', function (event) {
				              saveOnChange(this, event);
				            });
				          }

			$(input).on('change textInput input', function (event) {
				// event.stopPropagation(); event.stopImmediatePropagation();
				if(oldval !== $(input).val()) {
					$(this).cardinput().handleChange();
					oldval = $(input).val();
				}
			});
		}
  }

  jstree(elem) {
    $(document).ready(() => {
      const tree = $('.tree', (elem && elem[0] || document));
      if (!tree || !tree.jstree) { return; }
      tree.jstree({
        "core" : {
          "themes" : {
            "responsive": false
          }
        },
        "plugins": ["types"]
      });
      tree.on('select_node.jstree', function(e,data) {
        const link = $('#' + data.selected).find('a');
        if (link.attr("href") !== "#" && link.attr("href") !== "javascript:;" && link.attr("href") !== "") {
          if (link.attr("target") === "_blank") {
            link.attr("href").target = "_blank";
          }
          return false;
        }
      });

      tree.on('after_open.jstree', (e, data) => {
        this.dynamicContent();
      });
    });
  }

  dynamicContent() {
    function initButtons() {
      const BUTTON_SELECTOR = '.ajax-change-content:not(.event-loaded)';

      const btns = document.querySelectorAll(BUTTON_SELECTOR);
      if (!btns || btns.length === 0) { return; }
      btns.forEach( btn => {
        btn.classList.add('event-loaded');
        btn.addEventListener('click', (e) => {

          function getPostTarget(elem) {
              const postObj = $(elem).data('post');
              try {
                const { target } = postObj;
                if ( target ) { return target; }
              } catch(err) {
                return false;
              }

              return false;
          }

          const CONTENT_SELECTOR = getPostTarget(btn);
          if (!CONTENT_SELECTOR) { throw Error("Can't find CONTENT_SELECTOR"); }

          $.ajax({
            url: Util.blackbox('/app/dynproc_anyval/'),
            type: "post",
            data: $(btn).data('post'),
          }).done(function(data) {
            const contentElm = $(CONTENT_SELECTOR);
            if (!contentElm) { throw Error(`${CONTENT_SELECTOR} not found`); }

            contentElm.html(data).promise().done(function() {
              initPlugins(CONTENT_SELECTOR);
              const tableElem = $(`${CONTENT_SELECTOR} .table-content`);
              if (tableElem.length) {
                tableElem.table();
              }
            });
          });
        });
      });

    }

    $(initButtons);
  }

	init() {
		this.select2();
    this.input();
    this.jstree(this.element);
    this.dynamicContent();

		$('.reset', this.element).unbind('click').bind('click', function(event) {
			event.stopPropagation();
			event.preventDefault();
			const el = $(this).closest('.card-input-container').find('input,select,textarea');
			el.attr('checked',false);
			el.each( (e, i) => { i.checked = false; });
			el.prop('checked', false);
			$.ajax({
				url: Util.blackbox('/app/dynproc/'),
				type: 'post',
				data: Util.serialize({value: null}, el.data('post')),
				success: function (data) {
					if (Number(data.status) === 1) {
						el.cardinput().handleSave();
					} else {
						el.cardinput().handleError(null, null, data.message);
					}
				},
				error: function (XMLHttpRequest, textStatus, errorThrown) {
					el.cardinput().handleError(XMLHttpRequest, textStatus, errorThrown);
				}
			});
		});

		for(let remark of $('.remark', this.element)) {
			if($(remark).find('input,textarea').val()) {
				$(remark).show();
			}
		}

		for(let ls in localStorage) {
			const regexp =/(.*)\.expanded/;
			const match = regexp.exec(ls);
			// let timeoutHandle;
			if(match) {
				const accordion_panel = $('.accordion-toggle[href="'+match[1]+'"]');
				// const accordion = accordion_panel.closest('.accordion');
				if(accordion_panel) {
					if(Number(localStorage[ls])) {
						accordion_panel.closest('.panel').find('.panel-collapse').collapse('show');
					} else {
						accordion_panel.closest('.panel').find('.panel-collapse').collapse('hide');
					}
				}
			}
		}

		const tabs = $(this.element).find('.tabbable-line');

		// console.log(tabs);

		$('.accordion', tabs).css('display','block');

		$('.accordion-toggle', tabs).click(function() {
			localStorage[$(this).attr('href') + '.expanded'] = $(this).hasClass('collapsed') ? 1 : 0;
		});


		$('li a[href*="#map"]', tabs).on('click', function() {
			// if($(this).find('').length) {
			const iframe = $('iframe.map');
			if(!iframe.data('initmap')) {
				iframe.attr('src', iframe.data('src'));
				iframe.data('initmap', true);
			}
			// }
		});

		if(window.location.href.includes('#map')) {
			const iframe = $('iframe.map');
			if(!iframe.data('initmap')) {
				iframe.attr('src', iframe.data('src'));
				iframe.data('initmap', true);
			}
		}

	}

}

$.fn.card = function () {
	'use strict';
	const card = new Card(this);
	if (card.error) {
		Toastr.error(card.error, 'Card initialization error');
		throw new Error(card.error);
	}
	return card;
};

class CardInput {
	constructor(element) {
		this.element = element;
		this.container = $(this.element).closest('.card-input-container');
		this.icon = this.container.find('.fa:first');
		this.isCalendar = !!this.icon.hasClass('calendar');
		this.parent = this.container.parent();
	}

	handleChange() {
		this.clearState();
		this.parent.addClass('has-warning');
		this.icon.addClass('fa-floppy-o').attr('data-original-title', 'Изменено');
	}

	handleProcess() {
		this.clearState();
		this.icon.addClass('fa-cog fa-spin').attr('data-original-title', 'Сохранение...');
	}

	handleSave() {
		this.clearState();
		this.parent.addClass('has-success');
		this.icon.addClass('fa-check').attr('data-original-title', 'Сохранено').delay(500).fadeOut(500).promise().done(function() {
			if(this.isCalendar) {
				this.icon.addClass('fa-calendar');
			}
		});
	}

	handleError(XMLHttpRequest, textStatus, errorThrown) {
		this.clearState();
		Toastr.error(errorThrown);
		this.parent.addClass('has-error');
		this.icon.addClass('fa-warning').attr('data-original-title', 'Ошибка сохранения: ' + errorThrown);
	}

	clearState() {
		this.icon.removeClass('fa-calendar fa-floppy-o fa-cog fa-spin fa-warning fa-check').show();
		this.parent.removeClass('has-success has-warning has-error');
	}
}

$.fn.cardinput = function() {
	'use strict';
	const cardinput = new CardInput(this);
	if (cardinput.error) {
		Toastr.error(cardinput.error, 'Card initialization error');
		throw new Error(cardinput.error);
	}
	return cardinput;
};

